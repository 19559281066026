import "react-datepicker/dist/react-datepicker.css";

import {
  FaChevronRight,
  FaRegCalendarAlt,
  FaMinusCircle,
} from "react-icons/fa";
import {
  adminPlanId,
  awsPhotosPrefix,
  MATCH_OPTIONS,
  SUGGESTION_WHITELIST,
  isOptions,
  timeTravelOptions,
  osaOptions,
  isOptionsNumeric,
  isOptionAdmin,
  asyncSelectors,
  tooltipDelayLong,
  awsPrefix,
  dateOptions,
  matchMinWidthMap,
  minPhotosDate,
  photoTagTypes,
  pluralToSingular,
  monthsOptions,
  useCompaniesS3Key,
} from "constants/variables";
import Tooltip from "components/tooltip/index";
import { avatarFromName } from "constants/functions";
import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import MiniMap from "./map/index";
import DatePicker from "react-datepicker";
import { DebounceInput } from "react-debounce-input";
import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import { FallbackUserImage } from "components/fallback-user-image/FallbackUserImage";
import Mobile from "constants/responsive/mobile/index";
import { Option, TooltipText } from "constants/styled-components";
import Pill from "components/pill/index";
import Selector from "components/selector/index";
import { connect } from "react-redux";
import { media } from "constants/responsive";
import { getImageSource } from "shared/helpers/image";
import { asArray } from "shared/helpers/asArray";

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper = styled.div`
  animation: fadeIn 0.5s;
  transition: background 0.3s ease, padding 0.3s ease;
  background: ${(props) =>
    props.transparent
      ? "transparent"
      : props.pending
      ? props.theme.grey6
      : props.theme.pastelBlue};
  width: ${(props) => (props.center ? "auto" : "100%")};
  padding: ${(props) => (props.pending ? "1em 2em" : "0.75em 2em")};
  .react-select__indicators {
    opacity: ${(props) => (props.pending ? "1" : "0.5")};
  }
  .react-select__control {
    box-shadow: ${(props) =>
      props.pending ? "0px 1px 5px rgba(0, 0, 0, 0.1)" : "none"};
  }
  ${media.mobile`
    justify-content: center;
    flex-direction: column-reverse;
  `};
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${media.mobile`
    flex-wrap: wrap !important;
    justify-content: flex-start;
  `};
`;
const CloseContainer = styled.span`
  padding: 0.5em;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    padding-right: 0.5em;
  }
  svg,
  span {
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.midRed};
    color: ${(props) => props.theme.midRed};
    opacity: ${(props) => (props.pending ? "0.9" : "0.3")};
  }
  &:hover svg {
    opacity: 0.9;
  }
`;
const H3 = styled.h3`
  color: ${(props) => props.theme.grey2};
  margin: 0;
  width: ${(props) => (props.width ? props.width : "auto")};
  font-weight: bold;
  padding: 0 0.35em;
  margin-right: 0.5em;
  display: flex;
  text-transform: uppercase;
  text-align: ${(props) => (props.center ? "center" : "left")};
  align-items: center;
  justify-content: center;
  ${media.mobile`
    padding: 0.5em;
  `};
`;

const textOptions = ["text", "postal_zip"];
const textSelectorOptions = [
  {
    id: "is",
    value: "includes",
    label: "includes",
    noImg: true,
  },
  {
    id: "is_not",
    value: "excludes",
    label: "excludes",
    noImg: true,
  },
];

class Condition extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetched: {},
    };
    this.setSelectorMatch = this.setSelectorMatch.bind(this);
    this.setSelector = this.setSelector.bind(this);
    this.setSelectorIs = this.setSelectorIs.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.condition.match && this.props.condition.match === "text") {
      this.props.apiCall({
        method: "POST",
        component: "FEATURE_FOUND_TEXT_SEARCH",
        route: "user/feature_found",
        body: {
          user_id: this.props.dataUser.id,
          feature_id: 7,
        },
      });
    }
    if (
      this.props.conditionIndex === 0 &&
      !this.props.dataFeedSuggestionsMain.last_updated &&
      !this.props.dataLandingPageAbout &&
      !this.props.fetchReducer.fetching &&
      !this.state.fetched[this.props.condition.match]
    ) {
      if (
        !SUGGESTION_WHITELIST.includes(this.props.condition.match) ||
        (this.props.fetchReducer.fetching &&
          this.props.fetchReducer.component.includes(
            "FEED_FILTERS_GET_SUGGESTIONS"
          ))
      ) {
        return;
      }
      const currentFetched = this.state.fetched;
      this.setState({
        fetched: { ...currentFetched, [this.props.condition.match]: true },
      });
    }
  }

  onSearch(input) {
    this.setSelector({
      index: {
        ruleIndex: this.props.ruleIndex,
        conditionIndex: this.props.conditionIndex,
      },
      data: [{ id: input.target.value }],
    });
  }

  setSelectorMatch(data) {
    if (data.data.value === "map") {
      this.props.setData({
        type: "SHOW_FULLSCREEN_MODAL",
        data: {
          is: this.props.condition.is === "is",
          ruleIndex: data.index.ruleIndex,
          conditionIndex: data.index.conditionIndex,
        },
      });
    }
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_CHANGE_MATCH"
        : this.props.settings
        ? "AUTOMATION_CONDITION_CHANGE_MATCH"
        : "FILTER_CONDITION_CHANGE_MATCH",
      data: {
        value: data.data.value,
        ruleIndex: data.index.ruleIndex,
        conditionIndex: data.index.conditionIndex,
      },
    });
    if (
      !data.data.suggest ||
      (this.props.fetchReducer.fetching &&
        this.props.fetchReducer.component.includes(
          "FEED_FILTERS_GET_SUGGESTIONS"
        ))
    ) {
      return;
    } else {
      if (
        this.props.dataLandingPageAbout &&
        this.props.dataLandingPageAbout.id &&
        this.props.dataLandingPageAbout.route !== "collection" &&
        !this.props.dataFeedSuggestionsLandingPage[data.data.value]
      ) {
        this.props.apiCall({
          method: "GET",
          component: `FEED_FILTERS_GET_SUGGESTIONS_LP_${data.data.value.toUpperCase()}`,
          version: 2,
          route: `suggestions/${data.data.value}/${
            this.props.dataLandingPageAbout.is_retailer
              ? "retailer"
              : this.props.dataLandingPageAbout.is_brand
              ? "brand"
              : this.props.dataLandingPageAbout.route
          }/${
            this.props.dataLandingPageAbout.url_slug ||
            this.props.dataLandingPageAbout.id
          }`
        });
      }
    }
  }

  setSelectorIs(data) {
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_CHANGE_LOGIC"
        : this.props.settings
        ? "AUTOMATION_CONDITION_CHANGE_LOGIC"
        : "FILTER_CONDITION_CHANGE_LOGIC",
      data: {
        ruleIndex: data.index.ruleIndex,
        conditionIndex: data.index.conditionIndex,
        value: data.data.id,
      },
    });
  }

  setSelector(data) {
    if (
      data.data &&
      data.data.length > 0 &&
      SUGGESTION_WHITELIST.includes(this.props.condition.match)
    ) {
      const lastIndex = data.data.length - 1;
      const option = data.data[lastIndex];
      this.props.setData({
        type: `ADD_LOCAL_SUGGESTION_TO_${this.props.condition.match.toUpperCase()}`,
        data: {
          id: option.url_slug || option.id,
          img: option.img,
          noImg: option.noImg,
          name: option.value,
          source:
            useCompaniesS3Key.includes(option.source || option.type)
              ? "companies"
              : option.source || option.type
        },
      });
    }
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_CHANGE_ITEM"
        : this.props.settings
        ? "AUTOMATION_CONDITION_CHANGE_ITEM"
        : "FILTER_CONDITION_CHANGE_ITEM",
      data: {
        ruleIndex: data.index.ruleIndex,
        conditionIndex: data.index.conditionIndex,
        item: data.data
          ? asArray(data.data)
            .map((item) => ({
              id: item.id,
              img: item.img,
              noImg: item.noImg,
              name: item.value,
            }))
          : null,
      },
    });
  }

  removeCondition() {
    this.props.removeCondition(this.props.ruleIndex, this.props.conditionIndex);
  }

  setStartDate(date) {
    this.props.setStartDate(
      this.props.ruleIndex,
      this.props.conditionIndex,
      date
    );
  }

  setEndDate(date) {
    this.props.setEndDate(
      this.props.ruleIndex,
      this.props.conditionIndex,
      date
    );
  }

  handleMatchChange(event, ruleIndex, conditionIndex) {
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_CHANGE_MATCH"
        : this.props.settings
        ? "AUTOMATION_CONDITION_CHANGE_MATCH"
        : "FILTER_CONDITION_CHANGE_MATCH",
      data: {
        value: event.target.value,
        ruleIndex: ruleIndex,
        conditionIndex: conditionIndex,
      },
    });
  }

  handleLogicChange(event, ruleIndex, conditionIndex) {
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_CHANGE_LOGIC"
        : this.props.settings
        ? "AUTOMATION_CONDITION_CHANGE_LOGIC"
        : "FILTER_CONDITION_CHANGE_LOGIC",
      data: {
        value: event.target.id,
        ruleIndex: ruleIndex,
        conditionIndex: conditionIndex,
      },
    });
  }

  prepareSelectorOptions(options, sorted) {
    return (
      sorted ? options.sort((a, b) => (a.name > b.name ? 1 : -1)) : options
    ).map((item) => ({
      type: this.props.condition.match,
      value: item.name || item.label,
      img: item.img,
      id: item.id,
      label: (
        <Option>
          <img
            src={
              item.img
                ? `${awsPrefix}${item.source}/${item.img}`
                : item.name
                  ? avatarFromName(item.name)
                  : ""
            }
            alt={item.name}
          />
          {item.name || item.label}
        </Option>
      ),
    }));
  }

  disabledOption(value) {
    const { existingOptions } = this.props;
    return (
      existingOptions.includes("cities") && ["state", "country", "region"].includes(value) ||
      existingOptions.includes("states") && ["country", "region"].includes(value) ||
      existingOptions.includes("countries") && value === "region"
    )
  }

  renderMatchOptions() {
    const { condition, dataUser, existingOptions, dataLandingPageAbout } = this.props;
    const isPrivateSpace = !!dataLandingPageAbout?.collection_users;
    const isPremiumUser = dataUser?.plan_id > 1;

    const processOption = ({ value, label, suggest, plural }) => ({
      label,
      suggest,
      value: plural,
      disabled: this.disabledOption(value),
      excluded: (
        condition.match === plural ||
        existingOptions?.includes(plural) ||
        value === "user" && !isPremiumUser ||
        value === "location" && isPrivateSpace
      )
    });

    const filteredOptions = MATCH_OPTIONS.map((item) => ({
      label: item.group,
      options: item.options.map(processOption).filter(option => !option.excluded)
    }));

    if (dataLandingPageAbout?.show_osa_filters) {
      filteredOptions.push({
        label: "Stock Levels",
        suggest: false,
        value: "osa",
      });
    }
    return filteredOptions;
  }

  renderSelectOptions() {
    if (this.props.condition.match == "dates") {
      return dateOptions;
    } else if (this.props.condition.match == "time_travel") {
      return timeTravelOptions;
    } else if (this.props.condition.match == "osa") {
      return osaOptions;
    } else if (
      this.props.condition.match == "countries" &&
      this.props.dataInitialLoad &&
      this.props.dataInitialLoad.countries
    ) {
      return this.prepareSelectorOptions(
        this.props.dataInitialLoad.countries,
        false
      );
    } else if (
      this.props.condition.match == "tags" &&
      this.props.dataInitialLoad &&
      this.props.dataInitialLoad.tags
    ) {
      return [
        {
          label: "suggestions",
          options: this.prepareSelectorOptions(
            photoTagTypes.map((x) => ({
              img: x.img,
              source: "tags",
              name: x.name,
              label: x.name,
              id: x.id,
              value: x.id,
            })),
            false
          ),
        },
      ].concat({
        label: "all",
        options: this.prepareSelectorOptions(
          this.props.dataInitialLoad.tags,
          false
        ),
      });
    } else if (this.props.condition.match == "months") {
      return monthsOptions;
    } else if (this.props.condition.match == "users") {
      let userOptions = [
        {
          label: "suggestions",
          options: [
            {
              type: "users",
              value: "Me",
              noImg: true,
              id: this.props.dataUser.id,
              label: "Me",
            },
            {
              type: "users",
              value: "Anyone on my team",
              noImg: true,
              id: "anyone",
              label: "Anyone on my team",
            },
          ],
        },
      ];
      if (this.props.dataUser.team && this.props.dataUser.team.length > 0) {
        return userOptions.concat(
          this.props.dataUser.team.map((item) => ({
            type: "users",
            value: `${item.first_name} ${item.last_name}`,
            img: item.img,
            id: item.id,
            label: (
              <Option>
                <FallbackUserImage
                  src={item.img}
                  firstName={item.first_name}
                  lastName={item.last_name}
                />
                {`${item.first_name} ${item.last_name}`}
              </Option>
            ),
          }))
        );
      } else {
        return userOptions;
      }
    }
    if (
      this.props.settings &&
      this.props.dataInitialLoad &&
      this.props.dataInitialLoad[this.props.condition.match]
    ) {
      return this.prepareSelectorOptions(
        this.props.dataInitialLoad[this.props.condition.match],
        true
      );
    }
    const suggestions =
      this.props.dataLandingPageAbout && this.props.dataLandingPageAbout.id
        ? this.props.dataFeedSuggestionsLandingPage[this.props.condition.match]
        : this.props.conditionIndex === 0
        ? this.props.dataFeedSuggestionsMain[this.props.condition.match]
        : this.props.dataLandingPageAbout &&
          this.props.dataFeedSuggestionsLandingPage &&
          this.props.dataFeedSuggestionsLandingPage[this.props.condition.match]
        ? this.props.dataFeedSuggestionsLandingPage[this.props.condition.match]
        : this.props.dataFeedSuggestionsMain[this.props.condition.match];
    if (suggestions && suggestions.length > 0) {
      const suggestedOptions = [
        {
          label: "suggestions",
          options: this.prepareSelectorOptions(suggestions, false),
        },
      ];
      if (asyncSelectors.includes(this.props.condition.match)) {
        return suggestedOptions;
      } else if (
        this.props.dataInitialLoad &&
        this.props.dataInitialLoad[this.props.condition.match]
      ) {
        suggestedOptions.push({
          label: `all ${this.props.condition.match}`,
          options: this.prepareSelectorOptions(
            this.props.dataInitialLoad[this.props.condition.match],
            true
          ),
        });
      }
      return suggestedOptions;
    }
    if (
      this.props.dataInitialLoad &&
      this.props.dataInitialLoad[this.props.condition.match]
    ) {
      if (this.props.condition.match === "regions") {
        return this.prepareSelectorOptions(
          this.props.dataInitialLoad[this.props.condition.match],
          false
        );
      }
      return this.prepareSelectorOptions(
        this.props.dataInitialLoad[this.props.condition.match],
        true
      );
    }
    return [];
  }

  render() {
    const conditionIsOptions =
      this.props.condition.match === "osa"
        ? isOptionsNumeric
        : this.props.dataUser &&
          this.props.dataUser.plan_id === adminPlanId &&
          (this.props.condition.match == "products" ||
            this.props.condition.match == "brands" ||
            this.props.condition.match == "categories")
        ? [...isOptions, isOptionAdmin]
        : isOptions;
    const isSingle =
      this.props.condition.match == "users" ||
      this.props.condition.match == "dates" ||
      this.props.condition.match == "time_travel" ||
      this.props.condition.match == "question_id" ||
      this.props.condition.match == "osa";
    const pending = textOptions.includes(this.props.condition.match)
      ? Boolean(
          !this.props.condition.item ||
            (this.props.condition.item &&
              this.props.condition.item[0].id.length < 3)
        )
      : !(this.props.condition.item && this.props.condition.item.length > 0);
    return (
      <Wrapper
        key={`condition-${this.props.conditionIndex}-${this.props.condition.id}`}
        center={this.props.center && this.props.static}
        transparent={this.props.center}
        pending={pending}
        style={{
          borderBottom:
            this.props.lastConditionIndex === this.props.conditionIndex
              ? "2em solid transparent"
              : null,
        }}
      >
        <InnerWrapper
          key={`inner-${this.props.condition.match}-${this.props.condition.id}`}
        >
          {this.props.condition.match == "related" ? (
            <Flex>
              <H3>Photo is similar to</H3>
              <FallbackUserImage
                src={`${awsPhotosPrefix}compressed/${this.props.condition.item[0].id}-M`}
                width="3em"
                height="3em"
              />
            </Flex>
          ) : this.props.condition.match == "saved" ||
            this.props.condition.match == "uploaded" ? (
            <Flex>
              <FallbackUserImage
                src={this.props.dataUser.photo}
                width="3em"
                height="3em"
              />
              <H3>{`${this.props.dataUser.first_name} ${this.props.condition.match} the photo`}</H3>
            </Flex>
          ) : this.props.condition.disabled &&
            (this.props.condition.match == "saved" ||
              this.props.condition.match == "products" ||
              this.props.condition.match == "uploaded" ||
              this.props.condition.match == "cities") ? (
            <H3 textTransform="">{`${
              pluralToSingular[this.props.condition.match].upper
            } is ${this.props.condition.item[0].name}`}</H3>
          ) : (
            <Flex
              style={{
                width: "100%",
                flexWrap:
                  this.props.condition.match == "dates" ? "wrap" : "nowrap",
              }}
            >
              {this.props.conditionIndex == 0 ? (
                this.props.center ? (
                  <H3>WHEN</H3>
                ) : null
              ) : (
                <H3>AND</H3>
              )}
              <>
                {this.props.static ? (
                  <>
                    <H3>THE</H3>
                    <H3>
                      {pluralToSingular[this.props.condition.match] &&
                        pluralToSingular[this.props.condition.match].lower}
                    </H3>
                  </>
                ) : (
                  <>
                    <Selector
                      isSingle
                      portal
                      maxWidth="12em"
                      minWidth={
                        matchMinWidthMap[this.props.condition.match] || "11em"
                      }
                      defaultValue={
                        this.props.condition.match === "companies" &&
                        this.props.dataLandingPageAbout &&
                        this.props.dataLandingPageAbout.is_brand &&
                        !this.props.dataLandingPageAbout.is_retailer
                          ? [
                              {
                                label: "Brand",
                                value: this.props.condition.match,
                                noImg: true,
                                id: this.props.condition.match,
                              },
                            ]
                          : this.props.condition.match === "companies" &&
                            this.props.dataLandingPageAbout &&
                            !this.props.dataLandingPageAbout.is_brand &&
                            this.props.dataLandingPageAbout.is_retailer
                          ? [
                              {
                                label: "Retailer",
                                value: this.props.condition.match,
                                noImg: true,
                                id: this.props.condition.match,
                              },
                            ]
                          : pluralToSingular[this.props.condition.match]
                          ? [
                              {
                                label:
                                  pluralToSingular[this.props.condition.match]
                                    .upper,
                                value: this.props.condition.match,
                                noImg: true,
                                id: this.props.condition.match,
                              },
                            ]
                          : null
                      }
                      options={this.renderMatchOptions()}
                      filtered
                      isDisabled={
                        this.props.condition.disabled ||
                        this.props.condition.match === "question_id" ||
                        this.props.condition.match === "private_collections"
                      }
                      index={{
                        ruleIndex: this.props.ruleIndex,
                        conditionIndex: this.props.conditionIndex,
                      }}
                      clickAction={this.setSelectorMatch}
                    />
                  </>
                )}
                {!this.props.condition.match ? null : this.props.static ? (
                  <H3>
                    {this.props.condition.item &&
                    this.props.condition.item.length > 1
                      ? this.props.condition.is
                        ? "is one of"
                        : "is not one of"
                      : this.props.condition.is
                      ? "is"
                      : "is not"}
                  </H3>
                ) : textOptions.includes(
                    this.props.condition.match
                  ) ? null : this.props.condition.match == "dates" ? (
                  <H3>is</H3>
                ) : this.props.condition.match == "map" ? null : (
                  <Selector
                    key={`is-selector-${this.props.condition.match}-${this.props.condition.is}-${this.props.condition.id}`}
                    isSingle
                    maxWidth="10em"
                    minWidth="8em"
                    portal
                    isSearchable={false}
                    defaultValue={conditionIsOptions.filter(
                      (option) => option.id === this.props.condition.is
                    )}
                    isDisabled={
                      this.props.condition.match == "question_id" ||
                      this.props.condition.match == "products" ||
                      this.props.condition.match == "locations" ||
                      this.props.condition.match == "private_collections" ||
                      this.props.condition.disabled
                    }
                    index={{
                      ruleIndex: this.props.ruleIndex,
                      conditionIndex: this.props.conditionIndex,
                    }}
                    clickAction={this.setSelectorIs}
                    options={conditionIsOptions.filter(
                      (option) => option.id !== this.props.condition.is
                    )}
                  />
                )}
              </>
              {this.props.static ? null : (
                <Mobile
                  content={
                    <Flex onClick={this.removeCondition}>
                      <CloseContainer pending={pending}>
                        <FaMinusCircle />
                      </CloseContainer>
                    </Flex>
                  }
                />
              )}
              <span
                className={
                  this.props.static
                    ? "filter-dropdown"
                    : textOptions.includes(this.props.condition.match)
                    ? "textFilterContainer"
                    : this.props.condition.match === "map"
                    ? "mapFilterContainer"
                    : null
                }
              >
                {!this.props.condition.match ||
                this.props.condition.is === "is_none" ? null : this.props
                    .static ? (
                  <>
                    {this.props.condition.item &&
                      this.props.condition.item.length > 0 &&
                      this.props.condition.item.map((item, itemIndex) => (
                        <Pill
                          key={`${item}-${itemIndex}`}
                          id={item.id}
                          noHover
                          staticPill={this.props.condition.match == "dates"}
                          route={
                            useCompaniesS3Key.includes(
                              this.props.condition.match
                            )
                              ? "company"
                              : pluralToSingular[this.props.condition.match] &&
                                pluralToSingular[this.props.condition.match]
                                  .lower
                          }
                          source={
                            useCompaniesS3Key.includes(
                              this.props.condition.match
                            )
                              ? "companies"
                              : this.props.condition.match
                          }
                          img={item.img}
                          tab="about"
                          name={item.name || item.value}
                        />
                      ))}
                  </>
                ) : textOptions.includes(this.props.condition.match) ? (
                  <>
                    <Selector
                      isSingle
                      maxWidth="12em"
                      minWidth="11em"
                      portal
                      index={{
                        ruleIndex: this.props.ruleIndex,
                        conditionIndex: this.props.conditionIndex,
                      }}
                      defaultValue={textSelectorOptions.filter(
                        (option) => option.id === this.props.condition.is
                      )}
                      clickAction={this.setSelectorIs}
                      isSearchable={false}
                      options={textSelectorOptions.filter(
                        (option) => option.id !== this.props.condition.is
                      )}
                    />
                    <DebounceInput
                      type="text"
                      id="textFilter"
                      name="query"
                      value={
                        (this.props.condition.item &&
                          this.props.condition.item[0].id) ||
                        ""
                      }
                      placeholder={
                        this.props.condition.match === "text"
                          ? "Use double quotes to find exact phrases"
                          : "Filter locations by post code..."
                      }
                      autoComplete="off"
                      autoFocus
                      debounceTimeout={400}
                      onChange={this.onSearch}
                      style={{
                        width: "100%",
                        textTransform:
                          this.props.condition.match === "postal_zip"
                            ? "uppercase"
                            : "none",
                        boxSizing: "border-box",
                        borderRadius: "4px",
                        marginLeft: "0.25em",
                        padding: "0.75em",
                        outline: "0 !important",
                        backgroundColor: this.props.theme.white,
                        color: this.props.theme.grey1,
                        border: `1px solid ${this.props.theme.grey4}`,
                      }}
                    />
                  </>
                ) : this.props.condition.match === "map" ? (
                  this.props.condition.item ? (
                    <>
                      <H3>
                        {this.props.condition.is &&
                        this.props.condition.is === "is_not"
                          ? "excludes"
                          : "includes"}
                      </H3>
                      <MiniMap
                        is={this.props.condition.is}
                        data={this.props.condition.item[0]}
                        conditionIndex={this.props.conditionIndex}
                        ruleIndex={this.props.ruleIndex}
                      />
                    </>
                  ) : null
                ) : (
                  <Selector
                    key={`selector-${this.props.condition.match}-${this.props.ruleIndex}-${this.props.conditionIndex}`}
                    showSelectorType={asyncSelectors.includes(
                      this.props.condition.match
                    )}
                    portal
                    async={asyncSelectors.includes(this.props.condition.match)}
                    cacheOptions={this.props.condition.match === "retailers"}
                    isSingle={isSingle}
                    selectorType={this.props.condition.match}
                    filtered
                    index={{
                      ruleIndex: this.props.ruleIndex,
                      conditionIndex: this.props.conditionIndex,
                    }}
                    maxWidth="30em"
                    minWidth="22em"
                    isDisabled={
                      this.props.condition.match == "question_id" ||
                      this.props.condition.match == "products" ||
                      (this.props.condition.match == "locations" &&
                        this.props.dataLandingPageAbout &&
                        this.props.dataLandingPageAbout.route === "location") ||
                      this.props.condition.match == "private_collections" ||
                      this.props.condition.disabled
                    }
                    component="FILTER_RULES"
                    clickAction={this.setSelector}
                    placeholder={`${isSingle ? "Select" : "Search for"} ${
                      this.props.condition.match === "time_travel"
                        ? ""
                        : this.props.condition.match === "osa"
                        ? "stock level"
                        : this.props.condition.match + "..."
                    }`}
                    defaultValue={
                      this.props.condition.match === "time_travel"
                        ? timeTravelOptions.filter(
                            (x) =>
                              x.id ===
                              (this.props.condition.item &&
                                this.props.condition.item[0].id)
                          )
                        : this.props.condition.item &&
                          this.props.condition.item.length > 0
                        ? this.props.condition.item.map((item) => ({
                            type: this.props.condition.match,
                            value: item.name || item.label || item.value,
                            noImg:
                              item.noImg ||
                              this.props.condition.match == "dates",
                            img: item.img,
                            id: item.id,
                            label:
                              this.props.condition.match == "dates" &&
                              item.id === "custom" ? (
                                <Option>
                                  <FaRegCalendarAlt />
                                  Custom
                                </Option>
                              ) : (
                                <Option>
                                  {!item.noImg && this.props.condition.match !== "dates" &&
                                    (item.img || item.name || item.value) && (
                                      <img
                                        src={
                                          getImageSource({
                                            img: item.img,
                                            source: (
                                              this.props.condition.match === "collections" &&
                                              item.img !== "d6fd5d35-a370-4b36-8e14-de840bdf8890" &&
                                              this.props.dataLandingPageAbout?.source
                                            ) || this.props.condition.match,
                                            name: item.name || item.value
                                          })
                                        }
                                      />
                                    )
                                  }
                                  {item.name || item.label || item.value}
                                </Option>
                              ),
                          }))
                        : []
                    }
                    options={this.renderSelectOptions()}
                  />
                )}
              </span>
              {this.props.condition.match == "dates" &&
              this.props.condition.item &&
              this.props.condition.item[0].id == "custom" ? (
                <>
                  <DatePicker
                    disabledKeyboardNavigation
                    placeholderText="Start date"
                    selected={this.props.condition.startDate}
                    onChange={this.setStartDate}
                    selectsStart
                    withPortal
                    portalId="date-picker-portal"
                    minDate={minPhotosDate}
                    maxDate={new Date()}
                    showYearDropdown
                    startDate={this.props.condition.startDate}
                    endDate={this.props.condition.endDate}
                    dateFormat="MMMM d, yyyy"
                  />
                  <FaChevronRight color={this.props.theme.grey2} />
                  <DatePicker
                    disabledKeyboardNavigation
                    placeholderText="End date"
                    selected={this.props.condition.endDate}
                    onChange={this.setEndDate}
                    selectsEnd
                    withPortal
                    portalId="date-picker-portal"
                    showYearDropdown
                    dateFormat="MMMM d, yyyy"
                    maxDate={new Date()}
                    startDate={this.props.condition.startDate}
                    endDate={this.props.condition.endDate}
                    minDate={this.props.condition.startDate || minPhotosDate}
                  />
                </>
              ) : null}
            </Flex>
          )}
          <DesktopOrTablet
            content={
              <Flex>
                {this.props.condition.disabled ||
                this.props.static ? null : this.props.alerts &&
                  this.props.conditionIndex == 0 ? null : (
                  <Tooltip
                    unmountHTMLWhenHide
                    delay={tooltipDelayLong}
                    html={<TooltipText maxWidth="800px">Remove</TooltipText>}
                    position="top"
                  >
                    <CloseContainer
                      onClick={this.removeCondition}
                      pending={pending}
                    >
                      <FaMinusCircle />
                    </CloseContainer>
                  </Tooltip>
                )}
              </Flex>
            }
          />
        </InnerWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  dataFeedSuggestionsLandingPage: state.dataFeedSuggestionsLandingPage,
  dataFeedSuggestionsMain: state.dataFeedSuggestionsMain,
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataInitialLoad: state.dataInitialLoad,
  fetchReducer: state.fetchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Condition)
);
